.footer {
    width: 100%;
    min-height: $footer-height;
    background: $brand-color;
    color: $brand-font-color;
    padding: .5em;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
        margin-right: 1em;
    }

    a {
        color: $brand-font-color;

        @media (max-width: map_get($grid-breakpoints, 'sm')) {
            display: block;
            margin-right: 5vw;
        }

        @media (min-width: map_get($grid-breakpoints, 'sm')) {
            &:after {
                content: ' | ';
            }

            &:last-child:after {
                content: '';
            }
        }
    }
}
