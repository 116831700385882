.steps {
    text-align: center;
    margin: 2em auto 1em;
    padding: 0;

    .how-to {
        background: darken($gray-100, 2.5);

        .row {
            max-width: map_get($grid-breakpoints, 'md');
            margin: 0 auto;
            padding: 2em 0;

            img {
                max-width: 160px;
                aspect-ratio: 160 / 120;
                object-fit: contain;
            }

            p {
                font-size: 1.2em;
            }

            .arrow {
                background: url('../images/icon-arrow.png') center no-repeat;
                width: 50px;
                height: 100px;
            }

            @media (max-width: map_get($grid-breakpoints, 'md')) {
                flex-direction: column;

                .arrow {
                    transform: rotate(90deg);
                    flex-basis: auto;
                }

                .d-md-none {
                    margin-top: 1em;
                }
            }
        }
    }
}
