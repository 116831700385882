.points-section {
    padding: 2em;
    background: darken($gray-100, 2.5);
    position: sticky;
    top: 0;
    z-index: 1;
    margin-top: -21px;

    @media (max-width: map_get($grid-breakpoints, 'sm')) {
        padding: 2em 0;
        position: relative;
    }

    .points {
        font-size: 1.75em;
        font-weight: 700;
        padding-right: .5em;
    }

    img {
        height: 60px;
    }

    h2.user-title {
        font-weight: 700;
        color: $brand-color;
    }

    @media (max-width: map_get($grid-breakpoints, 'sm')) {
        .col-sm {
            margin-bottom: 1rem;
        }

        .btn {
            margin-bottom: 1rem;
            width: 100%;
        }
    }
}
