// Fonts
@import 'fonts';
// Variables
@import 'variables';
// Bootstrap
@import '~bootstrap/scss/bootstrap';
// Font-Awesome
@import '~font-awesome/scss/font-awesome';
// Dropzone (Customized)
@import '../../vendor/imi/loyalty-module/src/resources/sass/dropzone';
// Flatpickr
@import '~flatpickr/dist/flatpickr.css';

@import 'mainContent';

.agreement-information {
    margin-left: 15px;
}

@import '../../vendor/imi/loyalty-module/src/resources/sass/mixins';
@import '../../vendor/imi/loyalty-module/src/resources/sass/general';
@import '../../vendor/imi/loyalty-module/src/resources/sass/button';
@import '../../vendor/imi/loyalty-module/src/resources/sass/header';
@import '../../vendor/imi/loyalty-module/src/resources/sass/steps';
@import '../../vendor/imi/loyalty-module/src/resources/sass/footer';
@import '../../vendor/imi/loyalty-module/src/resources/sass/form';
@import '../../vendor/imi/loyalty-module/src/resources/sass/content';
@import '../../vendor/imi/loyalty-module/src/resources/sass/points-section';
@import '../../vendor/imi/loyalty-module/src/resources/sass/bonus';
@import '../../vendor/imi/loyalty-module/src/resources/sass/bonuses-page';
