.bonuses {
    .row {
        margin-bottom: 2em;
    }

    .bonus {
        text-align: center;

        img {
            display: block;
            width: 100%;

            &.is-not-elligable {
                filter: grayscale(100%);
            }
        }

        .points {
            font-size: 1.5em;
        }
    }
}
