.header {
    z-index: 2;
    position: relative;

    .image-desktop {
        width: 100%;
    }

    .image-mobile {
        display: none;
    }
}

@media (max-width: map_get($grid-breakpoints, 'md')) {
    .header {
        .image-desktop {
            display: none;
        }

        .image-mobile {
            display: inherit;
            width: 100%;
        }
    }
}
