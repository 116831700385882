h1.title {
    color: $brand-color;
    font-weight: 700;
    text-transform: uppercase;
    margin: auto;
    text-align: center;
    // Divider Option
    //@include crone();

    @media (min-width: map_get($grid-breakpoints, 'sm')) {
        font-size: 5em;
    }

    &.small {
        font-size: 2.5em;
    }

    .subtitle {
        display: block;
        font-size: .5em;
        font-weight: 400;
    }
}

.flex-center {
    justify-content: center;
}

.modal-body.alert {
    margin-bottom: 0;
}

a:not(.btn, .info) {
    text-decoration: underline;
    font-style: italic;
    font-weight: bold;
}
