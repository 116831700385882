.btn {
    text-transform: uppercase;

    &.fullwidth {
        width: 100%;
    }

    &.large {
        font-weight: 700;
        font-size: 1.25em;
    }
}
