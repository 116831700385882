
// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: "Nunito", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66D9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

// Custom
$brand-color: #e80711;
$brand-font-color: #fff; // Use with $brand-color as background

$header-height: 60vh;
$footer-height: 60px;

// Bootstrap overrides
$primary: $brand-color;
$btn-border-radius: 0;

$input-border-radius: 0;
$input-border-color: $brand-color;
$input-focus-border-color: $brand-color;
$input-btn-focus-color: lighten($brand-color, 25);
$input-padding-x: 1rem;
$input-padding-y: .5rem;
