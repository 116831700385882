.bonuses {
    max-width: map_get($grid-breakpoints, 'md');

    img {
        max-width: 100%;
    }

    .points {
        text-align: center;
        font-weight: bold;
        font-size: 1.5em;
        color: $brand-color;
    }
}
